import AddImport from "./Add.tsx";
import ArrowDownImport from "./ArrowDown.tsx";
import BadgeAppleImport from "./BadgeApple.png";
import BadgeGoogleImport from "./BadgeGoogle.png";
import BagImport from "./Bag.tsx";
import BellImport from "./Bell.tsx";
import BlackImport from "./Black.jpeg";
import BookmarkImport from "./Bookmark.tsx";
import BookmarkWhiteImport from "./BookmarkWhite.tsx";
import BucketImport from "./Bucket.tsx";
import CancelledTagImport from "./CancelledTag.tsx";
import CareAgentImport from "./CareAgent.tsx";
import CareAgentBlackDotImport from "./CareAgentBlackDot.tsx";
import CareAgentLogOutImport from "./CareAgentLogOut.tsx";
import CheckImport from "./Check.tsx";
import ChevronDownImport from "./ChevronDown.tsx";
import ChevronUpImport from "./ChevronUp.tsx";
import CloseImport from "./Close.tsx";
import CloseIconImport from "./CloseIcon.tsx";
import CookieImport from "./Cookie.tsx";
import CreditCardImport from "./CreditCard.tsx";
import DevicesImport from "./Devices.png";
import DiscoveryImport from "./Discovery.tsx";
import DownArrowIconImport from "./DownArrowIcon.tsx";
import EditIconImport from "./EditIcon.tsx";
import ErrorImport from "./Error.tsx";
import FacebookIconImport from "./FacebookIcon.tsx";
import FaqImport from "./Faq.tsx";
import ForwardImport from "./Forward.tsx";
import FullScreenImport from "./FullScreen.tsx";
import Group19Import from "./Group19.jpeg";
import InactiveTagImport from "./InactiveTag.tsx";
import InformationImport from "./Information.tsx";
import InstagramIconImport from "./InstagramIcon.tsx";
import LeftArrowImport from "./LeftArrow.tsx";
import LoginBackdropImport from "./LoginBackdrop.png";
import MasterCardImport from "./MasterCard.tsx";
import MenuImport from "./Menu.tsx";
import MessengerIconImport from "./MessengerIcon.tsx";
import MyPerksImport from "./MyPerks.tsx";
import NewWindowImport from "./NewWindow.tsx";
import NextArrowIconImport from "./NextArrowIcon.tsx";
import PausedTagImport from "./PausedTag.tsx";
import PenImport from "./Pen.tsx";
import PendingPausedTagImport from "./PendingPausedTag.tsx";
import PictoPauseImport from "./PictoPause.tsx";
import PictoPlayImport from "./PictoPlay.tsx";
import PictoSoundImport from "./PictoSound.tsx";
import PlayImport from "./Play.tsx";
import PlayBlackImport from "./PlayBlack.tsx";
import PlayWhiteImport from "./PlayWhite.tsx";
import PlayerCloseImport from "./PlayerClose.tsx";
import PlayerExitImport from "./PlayerExit.tsx";
import PolygonDownImport from "./PolygonDown.tsx";
import PolygonUpImport from "./PolygonUp.tsx";
import ProfileImport from "./Profile.tsx";
import RecordImport from "./Record.tsx";
import RewindImport from "./Rewind.tsx";
import RightArrowImport from "./RightArrow.tsx";
import RoundPlayButtonImport from "./RoundPlayButton.tsx";
import SearchImport from "./Search.tsx";
import SearchNewImport from "./SearchNew.tsx";
import SelectorsImport from "./Selectors.tsx";
import SettingsImport from "./Settings.tsx";
import SettingsGreenImport from "./SettingsGreen.tsx";
import ShrinkScreenImport from "./ShrinkScreen.tsx";
import SoundImport from "./Sound.tsx";
import StarImport from "./Star.tsx";
import StartOverImport from "./StartOver.tsx";
import SubtitlesImport from "./Subtitles.tsx";
import SubtitlesGreenImport from "./SubtitlesGreen.tsx";
import SubtitlesRedImport from "./SubtitlesRed.tsx";
import SubtitlesWhiteImport from "./SubtitlesWhite.tsx";
import SuccessImport from "./Success.tsx";
import SynopsisImport from "./Synopsis.tsx";
import SynopsisGreenImport from "./SynopsisGreen.tsx";
import TopArrowImport from "./TopArrow.tsx";
import TrashImport from "./Trash.tsx";
import TwitterLogoImport from "./TwitterLogo.tsx";
import UpArrowImport from "./UpArrow.tsx";
import UserImport from "./User.tsx";
import ViewMoreArrowImport from "./ViewMoreArrow.tsx";
import VisaImport from "./Visa.tsx";
import WarningImport from "./Warning.tsx";
import YoutubeLogoImport from "./YoutubeLogo.tsx";
import arrowleftImport from "./arrow_left.tsx";
import backdropImport from "./backdrop.jpg";
import burgerImport from "./burger.tsx";
import burgerCloseImport from "./burgerClose.tsx";
import checkmarkImport from "./checkmark.tsx";
import facebookImport from "./facebook.png";
import faviconImport from "./favicon.ico";
import instagramImport from "./instagram.png";
import joinLiveImport from "./joinLive.tsx";
import landscapeImport from "./landscape.jpg";
import logoImport from "./logo.tsx";
import logoPNGImport from "./logoPNG.png";
import messengerImport from "./messenger.png";
import posterImport from "./poster.jpg";
import xImport from "./x.png";
import youtubeImport from "./youtube.png";

const clientAssets = {
    'Add': AddImport,
    'ArrowDown': ArrowDownImport,
    'BadgeApple': BadgeAppleImport,
    'BadgeGoogle': BadgeGoogleImport,
    'Bag': BagImport,
    'Bell': BellImport,
    'Black': BlackImport,
    'Bookmark': BookmarkImport,
    'BookmarkWhite': BookmarkWhiteImport,
    'Bucket': BucketImport,
    'CancelledTag': CancelledTagImport,
    'CareAgent': CareAgentImport,
    'CareAgentBlackDot': CareAgentBlackDotImport,
    'CareAgentLogOut': CareAgentLogOutImport,
    'Check': CheckImport,
    'ChevronDown': ChevronDownImport,
    'ChevronUp': ChevronUpImport,
    'Close': CloseImport,
    'CloseIcon': CloseIconImport,
    'Cookie': CookieImport,
    'CreditCard': CreditCardImport,
    'Devices': DevicesImport,
    'Discovery': DiscoveryImport,
    'DownArrowIcon': DownArrowIconImport,
    'EditIcon': EditIconImport,
    'Error': ErrorImport,
    'FacebookIcon': FacebookIconImport,
    'Faq': FaqImport,
    'Forward': ForwardImport,
    'FullScreen': FullScreenImport,
    'Group19': Group19Import,
    'InactiveTag': InactiveTagImport,
    'Information': InformationImport,
    'InstagramIcon': InstagramIconImport,
    'LeftArrow': LeftArrowImport,
    'LoginBackdrop': LoginBackdropImport,
    'MasterCard': MasterCardImport,
    'Menu': MenuImport,
    'MessengerIcon': MessengerIconImport,
    'MyPerks': MyPerksImport,
    'NewWindow': NewWindowImport,
    'NextArrowIcon': NextArrowIconImport,
    'PausedTag': PausedTagImport,
    'Pen': PenImport,
    'PendingPausedTag': PendingPausedTagImport,
    'PictoPause': PictoPauseImport,
    'PictoPlay': PictoPlayImport,
    'PictoSound': PictoSoundImport,
    'Play': PlayImport,
    'PlayBlack': PlayBlackImport,
    'PlayWhite': PlayWhiteImport,
    'PlayerClose': PlayerCloseImport,
    'PlayerExit': PlayerExitImport,
    'PolygonDown': PolygonDownImport,
    'PolygonUp': PolygonUpImport,
    'Profile': ProfileImport,
    'Record': RecordImport,
    'Rewind': RewindImport,
    'RightArrow': RightArrowImport,
    'RoundPlayButton': RoundPlayButtonImport,
    'Search': SearchImport,
    'SearchNew': SearchNewImport,
    'Selectors': SelectorsImport,
    'Settings': SettingsImport,
    'SettingsGreen': SettingsGreenImport,
    'ShrinkScreen': ShrinkScreenImport,
    'Sound': SoundImport,
    'Star': StarImport,
    'StartOver': StartOverImport,
    'Subtitles': SubtitlesImport,
    'SubtitlesGreen': SubtitlesGreenImport,
    'SubtitlesRed': SubtitlesRedImport,
    'SubtitlesWhite': SubtitlesWhiteImport,
    'Success': SuccessImport,
    'Synopsis': SynopsisImport,
    'SynopsisGreen': SynopsisGreenImport,
    'TopArrow': TopArrowImport,
    'Trash': TrashImport,
    'TwitterLogo': TwitterLogoImport,
    'UpArrow': UpArrowImport,
    'User': UserImport,
    'ViewMoreArrow': ViewMoreArrowImport,
    'Visa': VisaImport,
    'Warning': WarningImport,
    'YoutubeLogo': YoutubeLogoImport,
    'arrow_left': arrowleftImport,
    'backdrop': backdropImport,
    'burger': burgerImport,
    'burgerClose': burgerCloseImport,
    'checkmark': checkmarkImport,
    'facebook': facebookImport,
    'favicon': faviconImport,
    'instagram': instagramImport,
    'joinLive': joinLiveImport,
    'landscape': landscapeImport,
    'logo': logoImport,
    'logoPNG': logoPNGImport,
    'messenger': messengerImport,
    'poster': posterImport,
    'x': xImport,
    'youtube': youtubeImport,
}
export default clientAssets
